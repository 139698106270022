import logo from "./logo.svg";
import "./App.css";
import Login from "./Login/Login";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Login />
    </QueryClientProvider>
  );
}

export default App;
